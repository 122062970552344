import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Logo from "../images/hackLogo.png"

const About = () => (
  <Layout>
    <AboutHeader>
      <AboutWrapper>
        <img src={Logo} alt="Hack Altamont logo" />
        <AboutDetails>
          <h1 className="display-1">Welcome to Hack Altamont</h1>
          <p>
            Thanks for checking our site out! If you're here, you're either a
            teacher, a parent, or just a curious visitor. Either way, we're
            happy to have you looking through our posts and resources. This site
            is intended to do a few things simultaneously: Primarily, as
            educators, we want to share the problems we encounter and the
            lessons we learn on a daily basis. We hope that documenting our
            workflows, talking about our processes, and outlining our solutions
            help you, too!
          </p>
          <ButtonContainer>
            <Link to="/blog">Blog</Link>
            <Link to="/resources">Resources</Link>
          </ButtonContainer>
        </AboutDetails>
      </AboutWrapper>
    </AboutHeader>
  </Layout>
)

const AboutHeader = styled.div`
  background: black;
  min-height: 100vh;
`

const AboutWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
  place-items: start center;

  img {
    margin-top: 50%;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const AboutDetails = styled.div`
  display: grid;
  color: var(--white);
  padding-top: 100px;
  margin-right: 50px;

  @media (max-width: 800px) {
    margin-left: 50px;
    margin-bottom: 100px;
  }
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  a {
    color: var(--white);
    text-decoration: none;
    text-align: center;
    border: solid 2px var(--white);
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    transition: ease-in-out 0.3s;
  }

  a:hover {
    color: black;
    border: solid 2px black;
    background: var(--white);
  }
`

export default About
